import * as React from "react";
import { LeadContext } from "../../lib/context/lead";
import { getAddresses } from "../../lib/modules/api";
import { Address, Error } from "../../lib/types";
import AddressForm from "../AddressForm";
import PostcodeForm from "../PostcodeForm";
import { FormError } from "../Shared";

const SelectAddress: React.FC = () => {
  const { postcode } = React.useContext(LeadContext);
  const [error, setError] = React.useState<Error | null>(null);
  const [isLoading, setLoading] = React.useState<Boolean>(false);
  const [addresses, setAddresses] = React.useState<Address[]>([]);

  React.useEffect(() => {
    if (postcode === "") {
      return;
    }

    const fetchAddresses = async () => {
      setLoading(true);

      const { data, error } = await getAddresses(postcode);

      setError(error);
      setAddresses(data);
      setLoading(false);
    };

    fetchAddresses();
  }, [postcode]);

  return addresses.length > 0 ? (
    <AddressForm addresses={addresses} />
  ) : (
    <>
      {error && <FormError message={error.message} theme="dark" />}
      <PostcodeForm isLoading={isLoading} />
    </>
  );
};

export default SelectAddress;
