import * as React from "react";
import Pill from "../Pill";

export const PillList: React.FC<{
  items: Array<string>;
}> = ({ items }) => {
  let pillList = items.map(function (value, index) {
    return (
      <span key={index}>
        <Pill name={value} />
      </span>
    );
  });

  return <>{pillList}</>;
};

export default PillList;
