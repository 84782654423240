import * as React from "react";
import * as Yup from "yup";
import styles from "./styles.module.css";
import { Address } from "../../lib/types";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { LeadContext } from "../../lib/context/lead";
import { FormError, Button, FormSelect } from "../Shared";

const AddressForm: React.FC<{ addresses: Address[] }> = ({ addresses }) => {
  const router = useRouter();
  const { setAddress } = React.useContext(LeadContext);
  const [loading, setLoading] = React.useState<Boolean>(false);

  const addressOptionsList = addresses.map((address) => {
    return address.formatted_address.filter((str) => str !== "").join(", ");
  });

  const formik = useFormik({
    initialValues: {
      address: "",
    },
    validationSchema: Yup.object({
      address: Yup.number().required("Please select an address."),
    }),
    onSubmit: (values: any) => {
      setLoading(true);
      setAddress(addresses[values.address]);
      return router.push("/start/providers").then(() => setLoading(false));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row mt-4">
        <div className="col-12">
          <div className={`${styles.input__fixed} mx-auto`}>
            <FormSelect
              handleChange={formik.handleChange}
              name="address"
              value={formik.values.address}
              optionsList={addressOptionsList}
              placeholder="Please select an address..."
            />
            {formik.touched.address && formik.errors.address ? (
              <FormError message="Please select an address." theme="dark" />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className={`${styles.input__fixed} mx-auto`}>
            <Button
              type="submit"
              name="button"
              title="Select"
              isLoading={loading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddressForm;
