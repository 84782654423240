import * as Yup from "yup";
import * as React from "react";
import { useFormik } from "formik";
import styles from "./styles.module.css";
import { postcodeRegex } from "../../lib/consts";
import { LeadContext } from "../../lib/context/lead";
import { FormError, FormInput, Button } from "../Shared";

const PostcodeForm: React.FC<{
  isLoading: Boolean;
}> = ({ isLoading }) => {
  const { setPostcode } = React.useContext(LeadContext);

  const formik = useFormik({
    initialValues: {
      postcode: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      postcode: Yup.string()
        .matches(postcodeRegex, "Please enter a valid postcode.")
        .required("Please enter your postcode.")
        .trim(),
    }),
    onSubmit: (values: any) => {
      setPostcode(values.postcode);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row mt-4">
        <div className="col-12">
          <div className={`${styles.input__fixed} mx-auto`}>
            <FormInput
              name="postcode"
              value={formik.values.postcode}
              handleChange={formik.handleChange}
              placeholder="HU18 1SN"
              isRequired={true}
            />
            {formik.touched.postcode && formik.errors.postcode ? (
              <FormError
                message="Please enter a valid postcode."
                theme="dark"
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className={`${styles.input__fixed} mx-auto`}>
            <Button
              type="submit"
              name="button"
              title="Find Address"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PostcodeForm;
