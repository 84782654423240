import * as React from "react";
import Link from "next/link";
import styles from "./styles.module.css";

const MobileNav: React.FC<{
  logoLink: string;
}> = ({ logoLink }) => {
  return (
    <div className="container">
      <div className="d-flex flex-fill">
        <Link href={logoLink}>
          <span className={styles.nav__logo}>
            <span className={styles.nav__logo_color}>Go</span>Fibrely.
          </span>
        </Link>
        <div className={styles.nav__mobile}></div>
      </div>
    </div>
  );
};

export default MobileNav;
