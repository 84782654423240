import * as React from "react";
import Image from "next/image";
import styles from "./styles.module.css";

const Providers: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <section className={`text-center py-5`}>
      <div className="pb-5">
        <div className={styles.title}>{title}</div>
      </div>
      <div
        className="
            text-center
            d-flex
            flex-wrap
            justify-content-center
            m-auto
          "
      >
        <div className="me-3 me-md-5">
          <Image
            className="d-inline-flex"
            src="/logos/sky.svg"
            alt="Sky Logo"
            width="100"
            height="30"
          />
        </div>
        <div className="me-3 ms-3 ms-md-5 me-md-5">
          <Image
            className="d-inline-flex"
            src="/logos/talktalk.svg"
            alt="TalkTalk Logo"
            width="100"
            height="30"
          />
        </div>
        <div className="me-3 ms-3 ms-md-5 me-md-5">
          <Image
            className="d-inline-flex"
            src="/logos/bt.svg"
            alt="BT Logo"
            width="60"
            height="30"
          />
        </div>
        <div className="ms-5 me-5 d-none d-md-inline-block">
          <Image
            className="d-none d-md-inline-flex"
            src="/logos/ee.svg"
            alt="EE Logo"
            width="100"
            height="30"
          />
        </div>
        <div className="ms-5 d-none d-md-inline-block">
          <Image
            className="d-none d-md-inline-flex"
            src="/logos/virgin_media.svg"
            alt="Virgin Media Logo"
            width="100"
            height="30"
          />
        </div>
      </div>
    </section>
  );
};

export default Providers;
