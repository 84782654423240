import * as React from "react";
import Link from "next/link";
import styles from "./styles.module.css";

const DesktopNav: React.FC<{
  logoLink: string;
}> = ({ logoLink }) => {
  return (
    <div className="container position-relative">
      <div className="d-flex flex-fill">
        <Link href={logoLink} className={styles.nav__logo}>
          <span className={styles.nav__logo}>
            <span className={styles.nav__logo_color}>Go</span>Fibrely.
          </span>
        </Link>
        <div className={styles.nav}></div>
      </div>
    </div>
  );
};

export default DesktopNav;
